import React, { useEffect, useContext } from "react";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import { setUserData } from "../../data/setUserData";
import Layout from "../../layouts/en";
import SEO from "components/seo";
import Member from "../../services/Member";
import Memberlang from "../../data/static-pages/pages/member/en";
import MemberProfilelang from "../../data/static-pages/pages/member/profile/en";
import MissingPointLang from "../../data/static-pages/pages/member/missing-point/en";
import FeedbackLang from "../../data/static-pages/pages/member/feed-back/en";
import ChangePasswordLang from "../../data/static-pages/pages/member/change-password/en";
import MyVoucherlang from "../../data/static-pages/pages/member/my-voucher/en";
import TransactionHistorylang from "../../data/static-pages/pages/member/transaction-history/en";
import MyReservationlang from "../../data/static-pages/pages/member/my-reservation/en";

const MemberPage = ({ ...props }) => {
  const userData = setUserData();
  const location = useLocation();
  const loginUrl = "/login";
  const editProfileUrl = "/member/changeprofile";
  const verifyPageUrl = "/verify-account";
  const completeProfilePageUrl = "/complete-profile";

  return (
    <Layout location={location}>
      <SEO
        title={"Welcome " + userData?.firstname}
        keyword="Welcome"
        decription="Welcome"
      />
      <Member
        props={props}
        editProfileUrl={editProfileUrl}
        location={location}
        loginUrl={loginUrl}
        Memberlang={Memberlang}
        MemberProfilelang={MemberProfilelang}
        MissingPointLang={MissingPointLang}
        FeedbackLang={FeedbackLang}
        ChangePasswordLang={ChangePasswordLang}
        MyVoucherlang={MyVoucherlang}
        TransactionHistorylang={TransactionHistorylang}
        MyReservationlang={MyReservationlang}
        verifyPageUrl={verifyPageUrl}
        completeProfilePageUrl={completeProfilePageUrl}
      />
    </Layout>
  );
};

export default MemberPage;
